import { _js } from '@ifixit/localize';
import { Autocompleter } from 'Shared/Autocompleter/Autocompleter';
import { truncate } from 'Shared/utils/text-utils';
import authorAutocompleterItemTemplate from 'hbs/author_autocompleter_item.hbs';
import blurbTemplate from 'hbs/blurb.hbs';
import suggestGlobalToggleTemplate from 'hbs/suggest_global_toggle.hbs';
import tagAutocompleterItemTemplate from 'hbs/tag_autocompleter_item.hbs';
import Template from 'Shared/template';

/**
 * Class that makes it easy to use the suggest API endpoint for
 * autocompleting guides, wikis, etc. This is the successor to BlurbFinder.
 */

const Suggest = new Class({
   Extends: Autocompleter.Request.API_2_0,

   el: null,

   initialize: function (el, options) {
      options = Object.merge(
         {
            fxOptions: null,
            doctypes: null,
            langid: null,
            fallbackToDefaultLanguage: false,
            className: 'blurb-finder',
            showImages: true,
            endpoint: 'suggest',
            maxChoices: 5,
            minLength: 0,
            truncateText: false,
            overflow: false,
            overflowMargin: 0,
            injectChoice: this.injectChoice.bind(this),
            getValue: this.getValue.bind(this),
            filterSubset: true,
            onShow: this.onShow.bind(this),
            queryParams: {},
         },
         options
      );

      options.getData = {};

      if (options.doctypes) {
         options.getData.doctypes = options.doctypes;
      }
      if (options.langid) {
         options.getData.langid = options.langid;
      }
      if (options.fallbackToDefaultLanguage) {
         options.getData.fallbackToDefaultLanguage = true;
      }
      delete options.doctypes;
      delete options.langid;
      delete options.fallbackToDefaultLanguage;

      this.el = el;

      this.parent(this.el, options.endpoint, options);
      this.url = this.request.request.options.url;

      // Prevent blur event from firing in Safari when dragging the scrollbar
      this.choices.addEvent('mousedown', ev => {
         ev.stop();
      });
   },

   query: function () {
      this.toggleIndicator(true);

      // if there are no queryValue the call 404's
      // so don't make the wasteful call
      if (!this.queryValue) {
         return;
      }
      let url = new URI(this.url + '/' + encodeURIComponent(this.queryValue));
      url.setData(this.options.queryParams);

      this.request.request.options.url = url.toString();

      let data = Object.clone(this.options.getData) || {};
      this.fireEvent('onRequest', [this.element, this.request, data, this.queryValue]);
      this.request.send({ data: data });
   },

   queryResponse: function (response) {
      this.toggleIndicator(false);

      this.fireEvent('onComplete', [this.element, this.request]);
      let decoded = JSON.parse(response);
      this.update(decoded && decoded.results);
   },

   /**
    * Returns the value to put in the input.
    */
   getValue: function (blurb) {
      return blurb.title;
   },

   /**
    * Resize the summary and image children (if the image exist) to fix the
    * available space.
    */
   onShow: function (el, choices, existing) {
      let choiceImage = choices.getElement('.blurb-image');

      if (choiceImage && choiceImage.isVisible()) {
         let blurbDimens = choices.getElement('.blurb-item').getDimensions(true);
         let blurbWidth = blurbDimens.width;
         let choiceSpacing = 20; // padding of choice + margin-right on image
         let choiceText = choices.getElements('.blurb-text');
         let imageWidth = choiceImage.getDimensions(true).width;

         let choiceTextWidth = blurbWidth - imageWidth - choiceSpacing;

         choiceText.each(text => {
            text.setStyle('width', choiceTextWidth);
         });
      }
   },
});

export const SuggestAuthors = new Class({
   Extends: Suggest,

   initialize: function (el, options) {
      options = Object.merge(
         {
            className: 'autocompleter-choices',
            endpoint: 'users/search',
            multiple: true,
            maxChoices: 5,
         },
         options
      );

      this.parent(el, options);
   },

   injectChoice: function (author) {
      let uniqueName = author.unique_username === null ? '' : '(@' + author.unique_username + ')';
      let data = {
         userid: author.userid,
         name: author.username,
         uniqueName: uniqueName,
         avatar: author.image.mini,
      };

      let template = Template.get(authorAutocompleterItemTemplate);

      let choice = template(data);
      choice.inputValue = author.username + uniqueName;
      this.addChoiceEvents(choice).inject(this.choices);
   },
});

export const SuggestDevices = new Class({
   Extends: Suggest,

   initialize: function (el, options) {
      options = Object.merge(
         {
            doctypes: 'device',
            truncateText: 90,
         },
         options
      );

      this.parent(el, options);
   },

   injectChoice: function (blurb) {
      if (!this.el.isDisplayed()) {
         return;
      }

      let text =
         blurb.text && this.options.truncateText
            ? truncate(blurb.text, this.options.truncateText, '…', ' ')
            : blurb.text;
      text = this.markQueryValue(text);

      let data = {
         showImage: this.options.showImages,
         blurbImage: blurb.image
            ? blurb.image.mini
            : window.shared_constants.GuideURI('TOPIC_NO_IMAGE_MINI'),
         title: blurb.title,
         displayTitle: this.markQueryValue(blurb.display_title),
         summary: text,
      };

      data.blurbId = blurb.newItem
         ? 'blurb-new'
         : 'blurb-' + blurb.dataType + '-' + blurb[blurb.dataType + 'id'];

      let template = Template.get(blurbTemplate);

      let choice = template(data);

      choice.store('blurb', blurb);
      choice.inputValue = this.options.getValue
         ? this.options.getValue(blurb)
         : blurb.type + ':' + blurb.id;

      if (blurb.newItem) {
         choice.addClass('blurb-add-new');
      }

      this.addChoiceEvents(choice).inject(this.choices);
   },
});

export const SuggestProducts = new Class({
   Extends: Suggest,

   initialize: function (el, options) {
      options = Object.merge(
         {
            doctypes: 'product',
            endpoint: 'suggest/products',
         },
         options
      );

      this.parent(el, options);
   },

   injectChoice: function (blurb) {
      if (!this.el.isDisplayed()) {
         return;
      }

      let text = blurb.text;
      let blurbid = 'blurb-' + blurb.dataType + '-' + blurb.productCode;

      if (!this.options.hideOptions) {
         blurbid = 'blurb-' + blurb.dataType + '-' + blurb.itemCode;

         if (blurb.optionTitle) {
            text = blurb.optionTitle;
         }
      }

      if (this.options.truncateText) {
         text = truncate(text, this.options.truncateText, '…', ' ');
      }

      text = this.markQueryValue(text);

      let data = {
         blurbId: blurbid,
         showImage: this.options.showImages,
         blurbImage: blurb.image
            ? blurb.image.mini
            : window.shared_constants.GuideURI('TOPIC_NO_IMAGE_MINI'),
         title: blurb.title,
         displayTitle: this.markQueryValue(blurb.title),
         summary: text,
         productBlurb: blurb,
      };

      let template = Template.get(blurbTemplate);
      let choice = template(data);

      blurb.queryValue = this.queryValue;
      choice.store('blurb', blurb);
      choice.inputValue = this.options.getValue
         ? this.options.getValue(blurb)
         : blurb.type + ':' + blurb.id;

      this.addChoiceEvents(choice).inject(this.choices);
   },
});

export const SuggestItems = new Class({
   Extends: SuggestDevices,

   initialize: function (el, options) {
      options = Object.merge(
         {
            doctypes: 'item',
         },
         options
      );

      this.parent(el, options);
   },
   /**
    * This adds the new item blurb if the query has no results, or none of the
    * result titles case-sensitively match the query.
    */
   queryResponse: function (response) {
      this.toggleIndicator(false);

      this.fireEvent('onComplete', [this.element, this.request]);
      let decoded = JSON.parse(response) || { query: '', results: [] };
      let newItemBlurb = {
         newItem: true,
         title: decoded.query,
         /* Translators: %1 -> itemType; %2 -> decodedQuery; */
         display_title: _js("Add new %1 '%2'")
            .replace('%1', this.options.itemType)
            .replace('%2', decoded.query),
         /* Translators: %1 -> itemType; */
         text: _js('Create a new %1 with this name.').replace('%1', this.options.itemType),
         mini: window.shared_constants.GuideURI('NO_IMAGE_MINI'),
      };

      let matches = decoded.results.filter(
         result => result.title.toLowerCase() === decoded.query.toLowerCase()
      );

      if (decoded.query && !matches.length) {
         decoded.results.push(newItemBlurb);
      }
      this.update(decoded && decoded.results);
   },
});

export const SuggestGuides = new Class({
   Extends: Suggest,

   initialize: function (el, options) {
      options = Object.merge(
         {
            doctypes: 'guide',
            prereqOnlyOkay: false,
            inProgressOkay: false,
            queryParams: {},
         },
         options
      );

      if (options.prereqOnlyOkay) {
         options.queryParams.includePrereqOnlyGuides = true;
      }

      if (options.inProgressOkay) {
         options.queryParams.includeInProgressGuides = true;
      }

      this.parent(el, options);
   },

   injectChoice: function (blurb) {
      if (!this.el.isDisplayed()) {
         return;
      }

      let id = 'blurb-' + blurb.dataType + '-' + blurb[blurb.dataType + 'id'];

      let img = blurb.image
         ? blurb.image.mini
         : window.shared_constants.GuideURI('GUIDE_NO_MAIN_IMAGE_MINI');

      let text =
         blurb.text && this.options.truncateText
            ? truncate(blurb.text, this.options.truncateText, '…', ' ')
            : blurb.summary || '';

      let template = Template.get(blurbTemplate);
      let choice = template({
         blurbId: id,
         showImage: this.options.showImages,
         blurbImage: img,
         title: blurb.title,
         displayTitle: this.markQueryValue(blurb.title),
         summary: this.markQueryValue(text),
      });

      blurb.queryValue = this.queryValue;
      choice.store('blurb', blurb);
      choice.inputValue = this.options.getValue
         ? this.options.getValue(blurb)
         : blurb.type + ':' + blurb.id;

      this.addChoiceEvents(choice).inject(this.choices);
   },
});

/**
 * Like SuggestGuides, but specific to a device, with the option to toggle
 * searching across all guides.
 */

export const SuggestDeviceGuides = new Class({
   Extends: SuggestGuides,

   global: false,

   wrapperEl: null,
   globalToggleEl: null,
   noResultsEl: null,
   noResultsQueryEl: null,

   initialize: function (el, options) {
      options = Object.merge(
         {
            device: null,
            className: 'blurb-finder',
            zIndex: 42,
            queryParams: {},
         },
         options
      );

      options.queryParams.guideDevice = options.device;

      let template = Template.get(suggestGlobalToggleTemplate);

      let wrap = template({
         choicesClass: options.className,
         zIndex: options.zIndex,
         objectNameSingular: App.objectNameSingular.toLowerCase(),
      })
         .hide()
         .inject(document.body, 'top');

      this.wrapperEl = wrap;
      this.globalToggleEl = wrap.getElement('.js-blurb-finder-global-toggle');
      this.noResultsEl = wrap.getElement('.js-no-matches');
      this.noResultsQueryEl = this.noResultsEl.getElement('span');

      this.globalToggleEl.addEvent('mousedown', ev => {
         ev.stop();
         this.toggleGlobal();
      });

      options.customChoices = wrap.getElement('ul');
      options.emptyChoices = this.showEmptyChoices;
      this.parent(el, options);
   },

   showChoices: function () {
      let match = this.options.choicesMatch;
      let first = this.choices.getFirst(match);
      if (first) {
         this.wrapperEl.removeClass('empty').show();
         this.parent();
         this.positionRelativeToInput(this.wrapperEl);
      } else {
         this.showEmptyChoices();
      }
   },

   showEmptyChoices: function () {
      this.choices.setStyle('height', 'auto');
      if (this.queryValue) {
         this.noResultsQueryEl.set('text', this.queryValue);
         this.positionRelativeToInput(this.wrapperEl);
         this.wrapperEl.addClass('empty').show();
      } else {
         this.hideChoices();
      }
   },

   hideChoices: function (clear) {
      this.parent(clear);
      this.wrapperEl.hide();
   },

   toggleGlobal: function () {
      this.global = !this.global;
      this.globalToggleEl.toggleClass('active');
      if (this.global) {
         delete this.options.queryParams.guideDevice;
      } else {
         this.options.queryParams.guideDevice = this.options.device;
      }
      this.query();
   },
});

/**
 * Class that makes it easy to use the suggest API endpoint for
 * autocompleting guides, wikis, etc. This is the successor to BlurbFinder.
 */

export const SuggestTags = new Class({
   Extends: Suggest,

   initialize: function (el, options) {
      options = Object.merge(
         {
            className: 'autocompleter-choices',
            endpoint: 'suggest/tags',
            multiple: true,
            maxChoices: 10,
            doctypes: 'all',
         },
         options
      );

      this.parent(el, options);
   },

   injectChoice: function (tag) {
      let data = {
         title: this.markQueryValue(tag),
      };

      let template = Template.get(tagAutocompleterItemTemplate);

      let choice = template(data);
      choice.inputValue = tag;
      this.addChoiceEvents(choice).inject(this.choices);
   },
});
