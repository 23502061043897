onDomReady(makeTextareasResizable);

function makeTextareasResizable() {
   $$('textarea').each(textarea => {
      let offset = window.opera
         ? textarea.offsetHeight +
           Number.parseInt(
              window.getComputedStyle(textarea, null).getPropertyValue('border-top-width')
           )
         : textarea.offsetHeight - textarea.clientHeight;
      let textareaMaxHeight = 300;
      let textareaInitialHeight = textarea.getSize().y;

      let resize = function (textarea) {
         textarea.style.height = textareaInitialHeight;
         textarea.style.overflow = 'auto';

         if (
            textarea.scrollHeight >= textareaInitialHeight &&
            textarea.scrollHeight < textareaMaxHeight
         ) {
            textarea.style.height = textarea.scrollHeight + offset + 'px';
         } else if (textarea.scrollHeight > textareaMaxHeight) {
            textarea.style.height = textareaMaxHeight + 'px';
         }
      };

      textarea.addEventListener &&
         textarea.addEventListener('input', event => {
            resize(textarea);
         });

      textarea.addEventListener &&
         textarea.addEventListener('mouseup', event => {
            if (textarea.getSize().y !== textareaInitialHeight) {
               textareaInitialHeight = textareaMaxHeight = textarea.getSize().y;
            }
         });
   });
}
