import 'Shared/mootools';
import 'Guide/guide_constants.js.php';
import 'shared-constants';
import 'Shared/translations';
import 'Shared/global';
import 'Guide/guide-all';
import 'lazysizes';
import 'Guide/module-all';
import 'Shared/PopupModal';
import 'Guide/Header/LanguageDropdown/language_dropdown_button';
import 'Shared/referral_links';
import 'Shared/ifixit-header-footer';
import { GoAffPro } from '@ifixit/analytics';
import { AddVercelToolBar } from 'Shared/features';

GoAffPro.trackVisitIfRequired();
AddVercelToolBar();
