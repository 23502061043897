import 'Shared/auth';
import 'Shared/button-toggle';
import 'Shared/blurb_finder';
import 'Shared/dropdown';
import 'Shared/content_dropdown_group';
import 'Shared/suggest';
import 'Guide/device_finder';
import 'Shared/status';
import 'Shared/keyboard_shortcuts';
import 'Shared/textarea_resize';
import 'Shared/status_notice';
import 'Shared/status_panel';
import 'Guide/icons';
import 'Guide/helptab';
import 'Shared/notify';
import 'Shared/FrameModules/Image/image_menu';
import 'Shared/wiki_text_editor';
import 'Shared/range_slider';
import 'Shared/shortcuts_modal';
import 'Shared/preferences';
import 'Shared/page_navigation_bar';
import 'Guide/PageNavigation/page_navigation';

void import('@ifixit/wiki-components');
