import { _js } from '@ifixit/localize';
import { tween } from 'shifty';
import { MediaItem } from '../MediaLibrary/item';
import { Auth } from 'Shared/auth';

// Add a menu to each image. The image must be standard size or larger, and
// the image must have the class 'hasMenu'.
onDomReady(() => {
   $$('img.' + ImageMenu.enableClass + ':not(.lazyload)').each(ImageMenu.installMenu);

   window.addEventListener('lazyloaded', ev => {
      if (ev.target.hasClass(ImageMenu.enableClass)) {
         if (ev.target.lazyloaded == 1) {
            ev.target.lazyloaded = 1;
         } else {
            ImageMenu.installMenu(ev.target);
         }
      }
   });
});

// eslint-disable-next-line no-var
export var ImageMenu = new Class({
   Implements: [Options, Events],

   options: {},

   // Whether or not the menu may be shown by a mouseenter event.
   enabled: false,

   showing: false,

   initialize: function (img, guid, options) {
      this.setOptions(options);
      this.img = img;
      this.guid = guid;
      this.menu = new Element('ul');
      this.menu.setAttribute('class', 'imageMenu');
      this.img.addEvents({
         mouseenter: this.show.bind(this),
         mouseleave: this.mouseLeave.bind(this),
      });
      this.enable();
   },

   show: function () {
      if (this.enabled) {
         if (this.showing) {
            // We might want to return this anyway, just to allow chaining.
            return null;
         }

         let after = this.img;
         let parent;
         while ((parent = after.getParent()).get('tag') != 'div') {
            after = parent;
         }
         this.menu.inject(after, 'before');
         let imgPos = this.img.getPosition(this.img.getOffsetParent());
         let d;
         if ((d = Number.parseInt(this.img.getStyle('border-left-width'), 10)) > 0) {
            imgPos.x += d;
         }
         if ((d = Number.parseInt(this.img.getStyle('border-top-width'), 10)) > 0) {
            imgPos.y += d;
         }
         this.menu.setStyles({
            right: imgPos.x,
            top: imgPos.y,
            opacity: 0,
         });
         tween({
            from: { opacity: 0 },
            to: { opacity: 1 },
            duration: 200,
            render: ({ opacity }) => (this.menu.style.opacity = opacity),
         });

         this.showing = true;
         this.fireEvent('menushow');
      }
      return this;
   },

   hide: function () {
      tween({
         from: { opacity: 1 },
         to: { opacity: 0 },
         duration: 200,
         render: ({ opacity }) => (this.menu.style.opacity = opacity),
      }).then(() => {
         this.dispose(this.menu);
      });
      this.showing = false;
      this.fireEvent('menuhide');
      return this;
   },

   dispose: function (obj) {
      obj.dispose();
   },

   mouseLeave: function (ev) {
      let related = ev.event.relatedTarget;
      if (related && related != this.menu && $(related).getParent('ul') != this.menu) {
         this.hide();
      }
   },

   enable: function () {
      this.enabled = true;
      return this;
   },

   disable: function () {
      this.enabled = false;
      return this;
   },

   addItem: function (title, action, attributes) {
      let a = new Element('a', {
         html: title,
      });
      let type = typeof action;
      if (type == 'string') {
         let href = action.substitute({ guid: this.guid });
         a.setAttribute('href', href);
      } else if (type == 'function') {
         a.addEvent('click', ev => {
            ev.stop();
            action(this.guid, this.img.getParent());
         });
      }
      if (attributes) {
         a.set(attributes);
      }
      let item = new Element('li').adopt(a).inject(this.menu);
      return item;
   },

   hideItem: function (item) {
      item.hide();
   },

   showItem: function (item) {
      item.show();
   },
});

ImageMenu.enableClass = 'hasMenu';

ImageMenu.installMenu = function (img) {
   let imgData;

   if (
      !img.hasClass(ImageMenu.enableClass) ||
      !(imgData = ImageMenu.parseSrc(img)) ||
      img.getParent('.noImageMenu')
   ) {
      return null;
   }

   let menu = new ImageMenu(img, imgData.guid);
   let copyItem;

   menu.addItem(
      window.guide_constants.fa('SEARCH_PLUS') + ' ',
      window.shared_constants.GuideURI('GUIDE_IMAGE_REDIRECT_ORIGINAL') + imgData.guid,
      { target: '_blank', title: _js('View Original'), rel: 'nofollow' }
   );

   // Add the copy option if the image doesn't have the "noCopy" class. This
   // menu item removes itself once selected using the reference to the menu
   // item returned by addItem.
   if (!img.hasClass('noCopy') && Auth.isLoggedIn()) {
      copyItem = menu.addItem(
         window.guide_constants.fa('FILES_O'),
         (guid, container) => {
            MediaItem.copyToMediaLibrary(guid, 'GuideImage', /* callback */ null, container);
         },
         { title: _js('Copy to Media Manager'), class: 'copy-media', rel: 'nofollow' }
      );
   }

   // Add the View Metadata option.
   menu.addItem(
      window.guide_constants.fa('INFO_CIRCLE'),
      window.shared_constants.GuideURI('GUIDE_IMAGE_METADATA') + imgData.guid,
      { title: _js('View Metadata'), class: 'media-metadata', rel: 'nofollow' }
   );

   img.store('ImageMenu:imageMenu', menu);
   return menu;
};

ImageMenu.getMenu = function (img) {
   return img.retrieve('ImageMenu:imageMenu');
};

ImageMenu.parseSrc = function (img) {
   let src = img.dataset.src || img.getAttribute('src');
   let match = src.match(/\/([\dA-Za-z]+)(\.(\w+))?($|\?)/);

   if (!match) {
      return false;
   }

   return {
      guid: match[1],
      size: match[2],
   };
};
