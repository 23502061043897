import { _js } from '@ifixit/localize';
import { Autocompleter } from 'Shared/Autocompleter/Autocompleter';
import { createRoot } from 'react-dom/client';

export const UserFinder = new Class({
   Extends: Autocompleter.Request.API_2_0,

   el: null,

   initialize: function (el, options) {
      if (!App.noAvatarUrl) {
         console.error('App.noAvatarUrl is not set.');
      }
      options = Object.merge(
         {
            className: 'blurb-finder',
            fxOptions: null,
            maxChoices: 5,
            delay: 100,
            truncateText: false,
            overflow: false,
            showReputation: true,
            injectChoice: this.injectChoice.bind(this),
         },
         options
      );

      this.el = el;

      this.parent(this.el, 'users/search', options);
      this.url = this.request.request.options.url;

      // Prevent blur event from firing in Safari when dragging the scrollbar
      this.choices.addEvent('mousedown', ev => {
         ev.stop();
      });
   },

   query: function () {
      this.toggleIndicator(true);

      let data = Object.clone(this.options.getData) || {};
      this.request.request.options.url = this.url + '/' + this.queryValue;
      this.fireEvent('onRequest', [this.element, this.request, data, this.queryValue]);
      this.request.send({ data: data });
   },

   injectChoice: function (user) {
      if (!this.el.isDisplayed()) {
         return;
      }
      let userImage;
      userImage = user.image && user.image.mini ? user.image.mini : App.noAvatarUrl;

      const reputationTitle = _js('Reputation');
      const blurbId = 'user-' + user.type + '-' + user.id;

      let choice = document.createElement('li');
      choice.className = 'blurb-item';
      choice.id = blurbId;
      choice.dataset.userid = user.userid;

      const root = createRoot(choice);
      root.render(
         <>
            <div className="blurb-image one-one">
               <img src={userImage} alt={user.username + ' Avatar'} />
            </div>
            <div className="blurb-text">
               <p className="blurb-title">
                  {user.username}
                  {this.showReputation
                     ? `<span class="reputation" title="{reputationTitle}">({user.reputation})</span>`
                     : ''}
               </p>
               <p>Userid: {user.userid}</p>
            </div>
         </>
      );

      choice.inputValue = '';

      this.addChoiceEvents(choice).inject(this.choices);
   },
});
